import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Image } from 'react-bootstrap';
import '../../css/loginSignup.css';
import headerImage from '../../assets/brandName2.svg';
import { Link as RouterLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Header from '../header/header';


const SignupPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateofBirth] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    const [financialAdvisor, setFinancialAdvisor] = useState('');
    const [zipCode, setZipCode] = useState('');
  
    const handleSubmit = (event) => {
      event.preventDefault();
      // Add login logic here
      console.log('Email:', email);
      console.log('Password:', password);
      console.log('FirstName:', firstName);
      console.log('ZipCode:', zipCode);
      console.log('DateofBirth:', dateOfBirth);
      console.log('PhoneNumber:', phoneNumber);

    };
  
    return (
        <>
            <Header />
            <Container fluid className="login-container">
                <Row className="vh-100">
                    <Col md={6} className="d-none d-md-block signup-image" />
                    <Col md={4} xs={12} className="d-flex login-form">
                    <div className="w-100">
                        <h2 className="text-center">Sign Up</h2>

                        <Form onSubmit={handleSubmit}>

                            <Row className='mb-3'>
                                <Col>
                                    <Form.Group controlId="firstName">
                                        <Form.Control 
                                        type="text" 
                                        placeholder="First Name" 
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                        />
                                        </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="lastName">
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Last Name" 
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <Form.Group controlId="dateOfBirth" className='mb-3 dateOfBirth'>
                                <Form.Control
                                type="date" 
                                format="dd/MM/YYYY"
                                value={dateOfBirth}
                                onChange={(e) => setDateofBirth(e.target.value)}
                                required
                                />
                                </Form.Group>
                                
                                </Col>    
                            </Row>

                            <Form.Group controlId="phoneNumber" className='mb-3'>
                                <Form.Control 
                                type="number" 
                                placeholder="Phone Number" 
                                value={phoneNumber}
                                onChange={(e) => setphoneNumber(e.target.value)}
                                required
                                />
                            </Form.Group>


                            <Form.Group controlId="zipCode" className='mb-3'>
                                <Form.Control 
                                type="number" 
                                placeholder="Zip Code" 
                                value={zipCode}
                                onChange={(e) => setZipCode(e.target.value)}
                                required
                                />
                            </Form.Group>

                            <Form.Group controlId="email" className='mb-3'>
                                <Form.Control 
                                type="email" 
                                placeholder="Email Address" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                />
                            </Form.Group>

                            <Form.Group controlId="password" className='mb-3'>
                                <Form.Control 
                                type="password" 
                                placeholder="Password" 
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                />
                            </Form.Group>

                            <Form.Group>
                                    <Form.Label>Are you a Financial Professional?</Form.Label>
                                    <div>
                                        <Form.Check type="radio" id="yes-option" name="agree"
                                            value="yes" label="Yes" checked={financialAdvisor ==='yes'}
                                            onChange={(e) => setFinancialAdvisor(e.target.value)} />
                                        
                                        <Form.Check type="radio" id="no-option" name="agree"
                                            value="no" label="No" checked={financialAdvisor ==='no'}
                                            onChange={(e) => setFinancialAdvisor(e.target.value)} />
                                    </div>
                            </Form.Group>
                            

                            <Button variant="primary" type="submit" className="w-100 mt-3" style={{ backgroundColor: '#00A86B', borderColor: '#4CAF50', color: 'white' }}>
                                Create Account
                            </Button>
                        </Form>

                        <div className="signup-link mt-3">
                        {"Already have an account?"} <RouterLink to="/login">Login here</RouterLink>
                        </div>
                    </div>
                    </Col>
                </Row>
    </Container>
    
        </>
    );
};

export default SignupPage;