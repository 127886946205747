import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Image } from 'react-bootstrap';
import '../../css/loginSignup.css';
import { Link as RouterLink } from 'react-router-dom';
import Header from '../header/header';


const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
  
    const handleSubmit = (event) => {
      event.preventDefault();
      // Add login logic here
      console.log('Email:', email);
      console.log('Password:', password);
    };
  
    return (
        <>
        <Header />
        <Container fluid className="login-container">
        <Row className="vh-100">
            <Col md={6} className="d-none d-md-block login-image" />
            <Col md={4} xs={12} className="d-flex login-form">
            <div className="w-100">
                <h2 className="text-center">Login</h2>
                <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail" className='mb-3'>
                    <Form.Control 
                    type="email" 
                    placeholder="Enter email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Control 
                    type="password" 
                    placeholder="Password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100 mt-3" style={{ backgroundColor: '#00A86B', borderColor: '#4CAF50', color: 'white' }}>
                    Login
                </Button>
                </Form>
                <div className="forgot-link mt-3">
				{"Forgot Password?"} <RouterLink to="/forgotPassword">Forgot Password</RouterLink>
			    </div>
                <div className="signup-link mt-3">
				{"Don't have an account?"} <RouterLink to="/signup">Create Account</RouterLink>
			    </div>
            </div>
            </Col>
        </Row>
    </Container>
    </>
    );
};

export default LoginPage;