import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './loginPage';
import Signup from './signupPage';
import ForgotPassword from './forgotPassword';

export default function App() {
  return (
   <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
      </Routes>
   </Router>
  );
}
