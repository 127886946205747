import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Button, Row, Col, Image } from 'react-bootstrap';
import headerImage from '../../assets/brandName2.svg';
import './header.css';

export default function Header() {
  return (
    <Navbar expand="lg" className="custom-navbar">
      <Container>
        <Navbar.Brand as={RouterLink} to="/home"><Image src={headerImage} alt="Company Logo" className="img-fluid" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </Container>
    </Navbar>
  );
}